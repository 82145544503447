import React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/error.png"
import "../assets/css/ourcss.css"
import Layout2 from "../components/Layout2"

const Error = () => {
  return <Layout2>


    <article className="about-text">
   
    <main className="error-page">
    
    <div className="error-container error500">
    <img  src={logo} alt="logo" style={{width:"37%"}}/>
      <Link to="/" className="btn">back home</Link>

    </div>

  </main>
    </article>
    
  </Layout2>
}

export default Error
